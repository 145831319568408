.all {
  font-family: sans-serif;
}

.header {
  position: relative;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
}

.header2 {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

.check-out-prev-picks {
  margin-top: 100px;
}

.imbed-spotify {
  border-radius: 12px;
  /* height: 100px; */
  height: 450px;
}
.imbed-apple-music {
  width: 100%;
  max-width: 660px;
  overflow: hidden;
  border-radius: 10px;
  /* height: 100px; */
  height: 450px;
}

.imbed-cont {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
