* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

body {
  overflow-x: hidden;
  /* position: "fixed"; */
}

.logos {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 30px;
  position: fixed;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}
/* 
.logos:before {
  left: 0;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.logos:after {
  right: 0;
  background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
} */

.logos-slide {
  display: flex;
  animation: infiniteScroll 100s linear infinite;
  will-change: transform;
  margin-bottom: -20px;
  filter: opacity(0.8);
}

.logos-slide img {
  height: 200px;
  width: 200px;
  flex-shrink: 0;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
  filter: opacity(0.5);
}

.logo2 {
  height: 125px;
  width: 125px;
  margin-right: -15px;
}

@keyframes infiniteScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
