body {
  line-height: 1.6;
}

.cont {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  margin: 0px auto;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  background-color: white;
}

.cont2 {
  margin: 80px auto;
  margin-top: 0px;
  margin-bottom: -5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  width: 85%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.my-centered-text {
  text-align: center;
}

p {
  margin: 20px 20px;
  padding-bottom: 12px;
}
