/* * {
  font-family: "Roboto-Bold";
  font-family: "Roboto-Regular";
} */

.App {
  text-align: center;
}

.header {
  font-size: 50px;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header2 {
  padding-bottom: 0px;
  text-align: center;
  padding-left: 10;
  padding-right: 10;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.image-scroller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 92px;
  z-index: -1; /* Ensure it is behind SalesPitch and OurMission */
}

.check-out-picks {
  font-size: 30px;
  text-align: center;
  /* margin-top:-10px; */
  margin-top: 50px;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Courier Prime";
  src: url("./fonts/Courier_Prime/CourierPrime-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.answers {
  font-style: italic;
  font-family: "Roboto-Regular";
  margin-bottom: 50px;
}

.answers2 {
  font-style: italic;
  font-family: "Roboto-Regular";
  margin-bottom: 5px;
}

/* TODO: Instead of .faq and .faq2, I could use variables for the max-width */
.faq {
  margin: auto;
  margin-bottom: 60px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq2 {
  margin: auto;
  margin-bottom: 60px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  max-width: 85%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .nav-bar-formatting-left{
  display:flex;
  align-items:center;
  margin-top:10px;
}

 .nav-bar-formatting-right{
  display:flex;
  align-items:center;
  margin-top:10px;
}  */

.nav-item {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-bar-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
}

.dev-album-or-song-art-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dev-album-or-song-art-item {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: vertical;
  flex-direction: column;
}
