.phone-su-cont2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 55px auto 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.phone-su-inner-logo-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.phone-su-logos {
  width: 100px;
  height: 100px;
}
